<template>
  <div>
    <van-nav-bar :left-arrow="true" left-text="返回" title="订单详情"  fixed :placeholder="true" @click-left="goBack"/>
    <div class="home-wrap" >
      <div class="main-card-wrap" >
        <div class="flex-between">
          <div class="left flex-column flex-around">
            <div class="title">订单ID：{{order.id}} <van-tag size="medium" plain type="primary">{{order.processUserName}}</van-tag></div>
            <van-divider :style="{ padding: '0 0px',margin:'0 0px' }"/>
            <div class="title">片名：{{order.movieName}}</div>
            <van-divider :style="{ padding: '0 0px',margin:'0 0px' }"/>
            <div class="title">价格：{{order.channelUnitPrice}} * {{order.quantity}} <span style="color:red;">（折后：{{order.orderUnitPrice}} * {{order.quantity}}）</span></div>
            <van-divider :style="{ padding: '0 0px',margin:'0 0px' }"/>
            <div class="title">城市：{{order.cityName}}</div>
            <van-divider :style="{ padding: '0 0px',margin:'0 0px' }"/>
          </div>
          <div class="right flex-top flex-center">
            <van-image
                width="60px"
                height="80px"
                fit="fit"
                :src="order.logo+'_200x200'"
            />
          </div>
        </div>
        <div class="left flex-column flex-around">
          <div class="title">影院：{{order.cinemaName}}</div>
          <van-divider :style="{ padding: '0 0px',margin:'0 0px' }"/>
          <div class="title">地址：{{order.cinemaAddress}}</div>
          <van-divider :style="{ padding: '0 0px',margin:'0 0px' }"/>
          <div class="title" :style="{marginTop:'20px'}">场次：<span style="font-weight: bold;font-size: 16px">{{order.playTimeText}}</span></div>
          <div class="title">版本：{{order.edition}}</div>
          <van-divider :style="{ padding: '0 0px',margin:'0 0px' }"/>
          <div class="title">影厅：{{order.hallName}}</div>
          <van-divider :style="{ padding: '0 0px',margin:'0 0px' }"/>
          <div class="title">座位：{{order.seats}}</div>
          <van-divider :style="{ padding: '0 0px',margin:'0 0px' }"/>
          <div class="title">备注：<van-tag round size="medium" :type="order.supportExchangeSeat === 'Y'?'primary':'danger'">{{order.supportExchangeSeat === 'Y'?'支持换座':'不支持换座'}}</van-tag></div>
        </div>
      </div>

      <div class="main-card-wrap" v-if="order.orderStatus !='S'">
        <div class="left flex-column flex-around">
          <van-button size="small" type="info" round block  @click="unlockSeat(order.id)">释放座位</van-button>
        </div>
      </div>

      <div class="main-card-wrap" >
          <van-form @submit="uploadTicketCode">
            <van-field
                v-model="order.xlh1"
                name="取票码1"
                label="取票码1"
                placeholder="请输入取票码"
                v-if="order.quantity>=1"
            />
            <van-field
                v-model="order.xlh2"
                name="取票码2"
                label="取票码2"
                placeholder="请输入取票码"
                v-if="order.quantity>=2"
            />
            <van-field
                v-model="order.xlh3"
                name="取票码3"
                label="取票码3"
                placeholder="请输入取票码"
                v-if="order.quantity>=3"
            />
            <van-field
                v-model="order.xlh4"
                name="取票码4"
                label="取票码4"
                placeholder="请输入取票码"
                v-if="order.quantity>=4"
            />
            <van-field
                v-model="order.xlh5"
                name="取票码5"
                label="取票码5"
                placeholder="请输入取票码"
                v-if="order.quantity>=5"
            />
            <van-field
                v-model="order.remark"
                rows="2"
                autosize
                type="textarea"
                maxlength="100"
                show-word-limit
                name="订单备注"
                label="订单备注"
                placeholder="请输入订单备注信息"
            />
            <van-field name="uploader" label="图片取票码">
              <template #input>
                <van-uploader v-model="files" multiple
                              :max-count="order.quantity"
                              :after-read="afterRead"
                              result-type="text"
                              :before-delete="beforeDelete"
                              :disabled="!order.canUploadTicketCode"
                />
              </template>
            </van-field>
            <div style="margin: 16px;" v-if="order.canUploadTicketCode">
              <van-button round block type="info" size="small" native-type="submit">保存取票码</van-button>
            </div>
          </van-form>
      </div>

    </div>
  </div>
</template>

<script>
import Api from '@/api-service'
export default {
  name: "Wait-Ticket",
  data(){
    return {
      order:{
      },
      orderId:'',
      files:[],
      filePaths:[]
    }
  },
  created() {
    this.orderId = this.$route.query.id;
    if(this.orderId){
      this.fetchData();
    }
  },
  methods:{
    fetchData(){
      Api.getOrderDetail({id: this.orderId}).then(res=>{
        this.order = res;
        this.files = res.ticketImgList;
        this.filePaths = res.ticketImgList;
      })
    },
    goBack(){
      this.$router.go(-1);
    },
    afterRead(file){
      this.uploadImage(file);
    },
    beforeDelete(file){
      var fileName = file.name;
      this.filePaths = this.filePaths.filter(item=> item.name !== fileName);
      this.files = this.filePaths;
    },
    uploadImage(file){
      Api.uploadImage({file:file.file}).then(res=>{
          this.files = [];
          this.filePaths.push(res);
          this.files = Object.assign([],this.filePaths);
      })
    },
    uploadTicketCode(){
      this.order.ticketImg = this.filePaths.map(item => item.url).join(',');
      Api.uploadOrderTicketCode(this.order).then(res=>{
        this.$notify.success("上传取票码成功");
      })
    },
    unlockSeat(id){
      Api.unlockOrder({id:id}).then(res=>{
        this.$notify.success("已发送释放座位请求");
      })
    }
  }
}
</script>

<style scoped lang="scss">
.home-wrap {
  height: 100%;
  background-color: #f3f3f3;

  .main-card-wrap {
    margin: 10px 10px;
    padding: 16px;
    border-radius: 6px;
    background-color: white;
    -moz-box-shadow: 2px 2px 3px #ccc;
    -webkit-box-shadow: 2px 2px 3px #ccc;
    box-shadow: 2px 2px 3px #ccc;
    .left {
      .title {
        font-size: 14px;
        margin-top: 6px;
      }
      .subtitle {
        font-size: 14px;
        margin-top: 6px;
      }
      .priceTitle{
        font-size: 16px;
        color: #c75450;

      }
      .otherTitle{
        font-size: 14px;
        margin-top: 6px;
        color: #999;
      }
      .btn {
        height: 34px;
        width: 120px;
        color: white;
        margin-top: 6px;
        border-radius: 4px;
        background-color: #8565bd;
      }
    }
    .right {
      margin-right: 8px
    }
  }
}
</style>
